@use "../utilities/variable" as *;


.notification{
    position: relative;
    text-align: center;

    &__icon{
        cursor: pointer;
        &:hover{
            color: $in-light;
        }
    }

    &__content{
        position: absolute;
        right: 50%;
        left: -50%;
        font-size: 0.9rem;
        width: 250px;
        z-index: 1000;

        &--transition{
            transition: all 350ms ease-in-out;
        }
    }

    &__badge{
        &--full{
            background-color: $in-red-orange!important;
        }

        &--empty{
            background-color: $in-dark!important;
        }
    }
}