@use "../utilities/variable" as *;
@use "../utilities/functions" as *;

.primary_navigation{

    background: transparent!important;

    &__links{
        a {
            position: relative;
            transition: all 350ms ease-in-out;


            &::after{
                transition: all 350ms ease-in-out;
            }

            &:hover{
                color: $in-primary!important;
            }
            
            &.active{

                color: $in-primary!important;
            }
            
        }
    }
}