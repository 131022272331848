*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 100%;
}

a {
    text-decoration: none;
}

ul {
    list-style-type: none;
}

