@use "../utilities/variable" as *;

.pricing{
    background-image: linear-gradient(rgba(33, 37, 41, 0.01), rgb(33, 37, 41) 85%), 
        radial-gradient(at 0% 0%, rgba(13, 110, 253, 0.5), rgba(0, 0, 0, 0) 50%), 
        radial-gradient(at 100% 0%, rgba(7, 18, 43, 0.5), rgba(0, 0, 0, 0) 50%), 
        radial-gradient(at 100% 50%, rgba(113, 44, 249, 0.5), rgba(0, 0, 0, 0) 50%), 
        radial-gradient(at 0% 50%, rgba(23, 1, 12, 0.5), rgba(0, 0, 0, 0) 50%);


        &__heading{

            position: relative;
            line-height: 1.5;
    
            &::after{
                content: "";
                width: 100%;
                height: 0.2rem;
                margin: auto;
                position: absolute;
                top: 100%;
                right: 0;
                background-color: $in-red-orange;
            }
    
        }

        &__card-title{
            font-weight: 700;
        }
}