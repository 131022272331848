@use "../utilities/variable" as *;

.info{
    &__container{
        background-image: url("./../../assets/smile-graphic.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom right;
    }

    &__heading{

        position: relative;
        line-height: 1.5;

        &::after{
            content: "";
            width: 100%;
            height: 0.2rem;
            margin: auto;
            position: absolute;
            top: 100%;
            right: 0;
            background-color: $in-red-orange;
        }

    }
}