// Colors

// in -> insekia

$in-primary: hsl(262, 88%, 62%);
$in-orange: hsl(31, 93%, 56%);
$in-red-orange: hsl(11, 89%, 52%);
$in-light: hsl(300, 6%, 94%);
$in-dark: hsl(261, 88%, 6%);
$in-green: hsl(81, 71%, 48%);
$in-light-purple: hsl(260, 77%, 76%);
$in-facebook: hsl(220, 46%, 48%);
$in-twitter: hsl(203, 89%, 53%);

// Map of theme colors
$in-theme-colors: (
    "in-primary" : $in-primary,
    "in-light-primary": $in-light-purple,
    "in-warning" : $in-orange,
    "in-danger" : $in-red-orange,
    "in-light" : $in-light,
    "in-success": $in-green,
    "in-dark" : $in-dark,
    "in-facebook" : $in-facebook,
    "in-twitter" : $in-twitter
);


// Utilities

$base-font-size: 1rem;
$base-padding: 1.3rem;

