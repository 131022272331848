@use "../../utilities/variable"as *;

.sidebar {
    position: relative;
   
    &--left {
        position: sticky;
        top: 0;
        height: calc(100vh - 70px);
        z-index: 100;
    }

    &__menu-item {
        color: hsl(0, 0%, 100%);    

        &:hover{
            color: $in-primary;
        }
    }

    &__heading {
        color: $in-light;
        text-transform: uppercase;
        letter-spacing: 0.21rem;
        font-size: 0.8rem;
        margin-bottom: 1rem;
    }

}