@use "../utilities/variable" as *;

.footer{

    &__nav-link{

        transition: all 350ms ease-in-out;

        &:hover{
            color: $in-light-purple !important;
        }

    }

}