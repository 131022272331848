@use "../utilities/variable" as *;

.features
{
    &__heading{

        position: relative;
        line-height: 1.5;

        &::after{
            content: "";
            width: 100%;
            height: 0.2rem;
            margin: auto;
            position: absolute;
            top: 100%;
            right: 0;
            background-color: $in-red-orange;
        }

    }


    &__card{
        transition: all 350ms ease-in-out;
        &:hover{
            cursor: pointer;
            scale: 1.02;
        }
    }
}