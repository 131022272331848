.sm-container{
    position: relative;
}

.sm-share{
    position: absolute;
    top: -353px;
    left: 0;
    z-index: 1110;

    &__link{
        display: inline-block;
        &:hover{
            scale: 1.5;
            transition: all 350ms ease-in-out;
        }
    }
}