.quote{

    &__content{
        background-image: url("./../../assets/proton-bg.png");
        background-size: auto;
        background-position: right center, left top;
        background-repeat: no-repeat, repeat;

    }


}