@use "../utilities/variable" as *;

.authenticate{
    background-image: linear-gradient(rgba(33, 37, 41, 0.01), rgb(33, 37, 41) 85%), 
        radial-gradient(at 0% 0%, rgba(13, 110, 253, 0.5), rgba(0, 0, 0, 0) 50%), 
        radial-gradient(at 100% 0%, rgba(7, 18, 43, 0.5), rgba(0, 0, 0, 0) 50%), 
        radial-gradient(at 100% 50%, rgba(113, 44, 249, 0.5), rgba(0, 0, 0, 0) 50%), 
        radial-gradient(at 0% 50%, rgba(23, 1, 12, 0.5), rgba(0, 0, 0, 0) 50%);


}